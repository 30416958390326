
header h1 {
    font-size: 2rem;
    margin: 0;
    padding: 10px 0;
    color: #03a9f4;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    position: relative;
    display: inline-block;
    background: linear-gradient(45deg, #03a9f4, #0d63aa);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: neonText 1.5s infinite alternate;
    overflow: hidden;
    border-radius:20px;
  }
  
  header h1::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.1) 100%);
    animation: slideText 3s infinite;
  }
  
  @keyframes neonText {
    0% {
      text-shadow: 0 0 5px #03a9f4, 0 0 10px #03a9f4, 0 0 20px #03a9f4, 0 0 40px #03a9f4, 0 0 80px #03a9f4, 0 0 90px #03a9f4, 0 0 100px #03a9f4, 0 0 150px #03a9f4;
    }
    100% {
      text-shadow: 0 0 10px #0d63aa, 0 0 20px #0d63aa, 0 0 30px #0d63aa, 0 0 40px #0d63aa, 0 0 70px #0d63aa, 0 0 80px #0d63aa, 0 0 100px #0d63aa, 0 0 150px #0d63aa;
    }
  }
  
  @keyframes slideText {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
  
  body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background: #000000;
    color: #555454;
  }
  
  .apod-container {
    text-align: center;
    margin: 20px auto;
    padding: 20px;
    background: rgba(146, 145, 145, 0.1);
    border-radius: 10px;
    max-width: 800px;
  }
  
  .apod-container img {
    max-width: 100%;
    border: 2px solid #000000;
    padding: 5px;
    border-radius: 10px; 
  }
  
  h2 {
    text-align: center;
    margin: 20px 0;
    font-size: 2rem;
  }
  
  .gallery {
    background: linear-gradient(to bottom right, rgb(0, 0, 0), rgb(54, 54, 54), rgb(91, 109, 143), rgb(42, 40, 189));
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
    width: 90%;
    margin-left: 5%;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 3px 4px 5px 0px rgba(128, 126, 128, 1);
    box-sizing: border-box;
  }
  
  .box img:hover {
    border-radius: 50%;
    box-shadow: 0px 0px 15px 15px #0337a8;
    transform: rotate(360deg);
    transition: transform 0.5s, box-shadow 0.5s;
  }
  
  .box {
    box-sizing: border-box;
    width: 300px;
    height: 440px;
    background: #000000;
    background: linear-gradient(to right, #afafaf 0%, #535355 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0f0c29', endColorstr='#302b63', GradientType=1);
    border: 1px solid rgb(0, 0, 0);
    background: linear-gradient(#0d63aa, #3a83c7, #ddd);
    backdrop-filter: blur(6px);
    border-radius: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-weight: bolder;
    margin-top: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .box img {
    width: 280px;
    border: 1px solid rgb(0, 0, 0);
    height: 290px;
    background: linear-gradient(#0d63aa, #3a83c7, #ddd);
  }
  
  .scroll-animation {
    animation: fadeInUp 1s ease-in-out;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  