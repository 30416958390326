body {
    background: linear-gradient(to bottom right, rgb(255, 255, 255), rgb(122, 121, 121), rgb(91, 109, 143), rgb(42, 40, 189));
flex-direction: column;
align-items: center;
justify-content: space-around;
font-weight: bolder;
margin-top: 20px;

border-radius:50%;
        -webkit-border-radius:50%;
        box-shadow: 0px 0px 15px 15px #0337a8;
        -webkit-box-shadow: 0px 0px 15px 15px #141414;
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        border-radius:50%;
        -webkit-border-radius:50%;
        box-shadow: 0px 0px 15px 15px #0337a8;
        -webkit-box-shadow: 0px 0px 15px 15px #010408;
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
}

